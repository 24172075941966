<template>
  <div class="pageContol formBOX">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">课件列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课件编辑</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              class
            >
              <el-form-item
                label="课件名称"
                prop="kpointName"
                class="form-item"
              >
                <el-input
                  v-model="ruleForm.kpointName"
                  :disabled="btnshow"
                  size="small"
                  placeholder="请输入课件名称"
                />
              </el-form-item>
              <el-form-item
                label="培训类型"
                prop="Trainingtype"
                class="form-item"
              >
                <el-input
                  v-if="btnshow"
                  v-model="ruleForm.Trainingtype"
                  :disabled="btnshow"
                  size="small"
                  placeholder="请输入课件名称"
                />
                <tree
                  v-else
                  ref="tree"
                  @eventBtn="childBack"
                  :ruleForm="ruleForm"
                  @clearParams="clearParams"
                  size="small"
                  type
                  typeStu
                />
              </el-form-item>
              <el-form-item
                label="课件学时"
                prop="kpointLessonNum"
                class="form-item"
              >
                <el-input-number
                  :disabled="true"
                  size="medium"
                  v-model="ruleForm.kpointLessonNum"
                  @change="handleChange"
                  :min="1"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="课后习题" class="form-item form-itemteache">
                <el-input
                  v-model="ruleForm.paperName"
                  :disabled="true"
                  size="small"
                  placeholder="请输入课后习题"
                />
              </el-form-item>
              <el-form-item
                label="关联讲师"
                prop="teacherId"
                class="form-item form-itemteache"
              >
                <el-select
                  v-model="ruleForm.teacherId"
                  clearable
                  filterable
                  size="small"
                  :disabled="btnshow"
                  placeholder="请选择讲师"
                >
                  <el-option
                    v-for="item in teachertree"
                    :key="item.teacherQualificationId"
                    :label="item.teacherQualificationName"
                    :value="item.teacherQualificationId"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <span>{{ item.teacherQualificationName }}</span>
                      <el-popover
                        placement="right"
                        v-if="item.teacherQualificationId != '-1000'"
                        trigger="hover"
                        @hide="setYlzpUrl"
                      >
                        <el-button type="text" slot="reference">证书</el-button>
                        <template>
                          <el-image
                            style="width: 400px; height: 400px"
                            fit="contain"
                            :src="ylzpUrl || item.certPhotos[0]"
                          >
                          </el-image>
                          <div class="ylzpList">
                            <el-image
                              class="ylzpList_1"
                              fit="contain"
                              v-for="(item1, index1) in item.certPhotos"
                              :key="index1"
                              :src="item1"
                              @mouseenter="setMouseenter(item1)"
                            >
                            </el-image>
                          </div>
                        </template>
                      </el-popover>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                label="课件封面"
                prop="thumbnail"
                :class="[ruleForm.id ? 'item-disabled' : '']"
              >
                <el-upload
                  :disabled="btnshow"
                  :on-change="handleAvatarSuccess2"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <div>
                    <el-image
                      :src="
                        ruleForm.thumbnail || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
                <p>建议上传尺寸224*160。</p>
              </el-form-item>
              <el-form-item
                label="是否是公共课："
                class="form-item form"
                prop="OpenClass"
              >
                <div>
                  <el-radio-group
                    v-model="ruleForm.OpenClass"
                    @change="radioChange"
                  >
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item
                label="是否开启试看："
                class="form-item form"
                prop="trySee"
              >
                <div>
                  <el-radio-group v-model="ruleForm.trySee" @change="trySee">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <!-- <el-form-item class="form-btn">
              
              </el-form-item>-->
            </el-form>
            <div class="form-btn">
              <el-button class="bgc-bv" @click="Cancel">取消</el-button>
              <el-button class="bgc-bv" @click="formSave('ruleForm')"
                >保存</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import Form from "@/mixins/Form";
import tree from "@/components/treePopup";
export default {
  name: "koniptDetails",
  mixins: [UploadPic, DatePicker, Form],
  components: {
    tree,
  },
  data() {
    return {
      ruleForm: {
        kpointId: this.$route.query.kpointId,
        kpointName: "",
        trainTypeId: "",
        kpointLessonNum: "",
        Exercises: "",
        paperId: "",
        kpointVideoId: "",
        thumbnail: "",
        fileKey: "",
        paperName: "",
        type: "",
        teacherId: "",
        OpenClass: false,
        Trainingtype: "",
        trySee: "",
      },
      rules: {
        kpointName: [
          { required: true, message: "请输入课件名称", trigger: "blur" },
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        kpointLessonNum: [
          { required: true, message: "请输入课件学时", trigger: "blur" },
        ],
        teacherId: [
          { required: true, message: "请选择讲师", trigger: "change" },
        ],
      },
      teachertree: [], // 讲师列表
      params: {}, // 培训类型参数
      ylzpUrl: "", // 讲师姓名 - 证书 - 预览当前
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    // 讲师姓名 - 证书 - 鼠标在当前照片
    setMouseenter(item) {
      this.ylzpUrl = item;
    },
    // 讲师姓名 - 证书 - 预览当前 - 隐藏时触发
    setYlzpUrl() {
      this.ylzpUrl = "";
    },
    getDefinitionType() {
      const DefinitionTypeList = this.$setDictionary("DEFINITION_TYPE", "list");
      const list = [];
      for (const key in DefinitionTypeList) {
        list.push({
          value: key,
          label: DefinitionTypeList[key],
        });
      }
      this.definitionTypeList = list;
    },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    init() {
      this.getteachertree();
      this.getDetail();
    },
    getDetail() {
      this.$post("/biz/courseware/kpointView", {
        kpointId: this.ruleForm.kpointId,
      }).then((result) => {
        if (result.status === "0") {
          let res = JSON.stringify(result);
          res = res.replace(/qualificationId/g, "teacherId");
          result = JSON.parse(res);
          this.ruleForm = {
            kpointId: result.data.kpointId,
            kpointName: result.data.kpointName,
            trainTypeId: result.data.trainTypeId,
            kpointLessonNum: result.data.kpointLessonNum,
            paperId: result.data.paperId,
            kpointVideoId: result.data.kpointVideoId,
            thumbnail: result.data.kpointLogo,
            teacherId: result.data.teacherId,
            paperName: result.data.paperName,
            OpenClass: result.data.publicKpoint,
            trySee: result.data.trySee,
          };
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (result.data.trainTypeId) {
            this.params.trainFormId = result.data.trainTypeId;
            name1 = result.data.trainTypeNamePath;
          }
          if (result.data.postId) {
            this.params.postFormId = result.data.postId;
            name2 = "/" + result.data.postName;
          }
          if (result.data.industryId) {
            this.params.industryFormId = result.data.industryId;
            name3 = "/" + result.data.industryNamePath;
          }
          if (result.data.occupationId) {
            this.params.occFormId = result.data.occupationId;
            name4 = "/" + result.data.occupationNamePath;
          }
          if (result.data.trainLevelId) {
            this.params.levelFormId = result.data.trainLevelId;
            name5 = "/" + result.data.trainLevelName;
          }
          let name = (name1 + name2 + name3 + name4 + name5).replace(/,/g, "-");
          this.ruleForm.Trainingtype = name;
        }
      });
    },
    // 讲师列表
    getteachertree() {
      this.$post("/biz/teacher/queryQualificationDictionary", {}).then(
        (res) => {
          if (res.status == 0) {
            this.teachertree = [
              ...res.data,
              {
                teacherQualificationId: "-1000",
                teacherQualificationName: "无讲师",
              },
            ];
          }
        }
      );
    },
    handlecascader(val) {
      this.ruleForm.Trainingtype = val;
    },
    handleteacher(val) {
      this.ruleForm.teachername = val;
    },
    // 上传图片
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.thumbnail = result.data.fileURL;
          this.ruleForm.fileKey = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    radioChange(val) {
      this.ruleForm.OpenClass = val;
      console.log(this.ruleForm.OpenClass);
      // if (val == 20) {
      //   this.checkShow = true;
      // } else {
      //   this.checkShow = false;
      // }
    },
    trySee(val) {
      this.ruleForm.trySee = val;
      console.log(this.ruleForm.trySee);
      // if (val == 20) {
      //   this.checkShow = true;
      // } else {
      //   this.checkShow = false;
      // }
    },
    // 保存
    formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          this.doAjax(formName);
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    doAjax() {
      let parmars = {
        kpointId: this.ruleForm.kpointId,
        kpointName: this.ruleForm.kpointName,
        qualificationId: this.ruleForm.teacherId,
        kpointLogo: this.ruleForm.fileKey,
        publicKpoint: this.ruleForm.OpenClass,
        trySee: this.ruleForm.trySee,
      };
      // 培训类型 trainForm postForm occForm levelForm industryForm
      if (this.params.trainFormId) {
        parmars.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmars.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmars.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmars.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmars.industryId = this.params.industryFormId;
      }
      this.$post("/run/courseware/update", parmars)
        .then((ret) => {
          if (ret.status == 0) {
            setTimeout(() => {
              this.$message({
                type: "success",
                message: "保存成功",
              });
              this.Cancel();
            }, 300);
          }
        })
        .catch(() => {
          return;
        });
    },
    Cancel() {
      // this.$router.back()
      this.$router.push({
        path: "/web/operate/koniptList",
        query: {
          refresh: true,
        },
      });
    },
  },
};
</script>
<style lang="less">
.formBOX {
  .el-form-item__content {
    width: 50%;
    .el-textarea__inner {
      resize: none;
    }
  }
  .mlf {
    .el-form-item__content {
      margin-left: 0 !important;
      display: flex;
      align-items: center;
    }
  }
  .el-input-number {
    width: 100%;
  }
  .form-itemteache {
    .el-form-item__content {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }
  .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button {
      width: 10rem;
    }
  }
  .el-select {
    width: 100% !important;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
  &:before {
    content: "\e6df" !important;
  }
}
</style>
<style lang="less" scoped>
.ylzpList {
  width: 400px;
  overflow-x: auto;
  white-space: nowrap;
  .ylzpList_1 {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
}
</style>

